import Vue from 'vue'
import App from './App.vue'
import VueI18n from 'vue-i18n'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'; 

import '@/utils/mixin.js'
Vue.use(VueI18n)

Vue.use(ElementUI)

// 通用样式
import '@/assets/styles/control.css'
import '@/assets/styles/common.css'
// 引入动画
import 'animate.css/animate.min.css'

import '@/utils/mixin.js'
import 'wowjs/css/libs/animate.css'
import { WOW } from 'wowjs'

import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'

Vue.config.productionTip = false
Vue.prototype.$wow = new WOW({
  boxClass: 'wow',
  animateClass: 'animated',
  offset: 0,
  live: true,
  mobile: true,
  callback(box) {
    console.log(box);
  }
})

Vue.use(VideoPlayer)

const i18n = new VueI18n({
  locale: 'zh', // 将要切换的语言，可以通过url拼的参数获取，用户行为select选择获取，本地manifest配置获取等，根据场景动态获取
  messages: {
    'zh': require('./api/languages/zh-CN.json'),  // 本地资源文件，我这里配2个语言，中文&英文，src下根据个人情况放置
    'en': require('./api/languages/en-US.json')
  }
});

new Vue({
  router,
  // store,
  // VideoPlayer,
  i18n,
  render: h => h(App)
}).$mount('#app')
