import Vue from 'vue'

Vue.mixin(
    {
        methods:{
            // 跳转路由
            jumpUrl(val){
                this.$router.push(val).catch((err) =>{
                    return err
                })
                // document.documentElement.scrollTop = 0;
            },
            jumpUrl2(val){
                this.$router.push(val).catch((err) =>{
                    return err
                })
                // document.documentElement.scrollTop = 0;
            }
        }
    }
)